<template>
  <div class="leave-word" v-if="isShow">
    <p class="leave-word-tips"> 请填写以下内容以方便我们及时联系您</p>
    <el-input class="leave-word-input" placeholder="姓名" v-model="formData.name"></el-input>
    <el-input class="leave-word-input" placeholder="手机号" v-model="formData.phone"></el-input>
    <el-input class="leave-word-input" placeholder="邮箱" v-model="formData.email"></el-input>
    <el-input class="leave-word-input" placeholder="请输入留言" v-model="formData.content" type="textarea" :rows="6"></el-input>

    <div class="leave-word-btn">
      <!-- <el-button size="medium" @click="cancel">取消</el-button>
      <el-button type="primary" size="medium" @click="submit">留言</el-button> -->
      <span class="leave-word-btn_common pointer" @click="cancel">取消</span>
      <span class="leave-word-btn_primary pointer" @click="submit">留言</span>
    </div>
  </div>
</template>
<script>
  export default {
    name:"",
    props:{
      isShow:{
        type:Boolean,
        default:false
      },
      requestMethods:{
        default(){
          return null
        }
      }
    },
    data() {
      return {
        formData:{
          name:"",
          phone:"",
          email:"",
          content:""
        }
      }
    },
    watch:{
      isShow(e){
        if(e){
          for(let key in this.formData){
            this.formData[key] = ''
          }
        }
      }
    },
    methods:{
      cancel(){
        this.$emit("update:isShow",false)
      },
      submit(){
        console.log(this.formData)
        if(!this.formData.name) return this.$message.warning("姓名输入有误")
        if(!/^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.formData.phone)) return this.$message.warning("手机号输入有误")
        if(!/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(this.formData.email)) return this.$message.warning("邮箱输入有误")
        if(this.formData.content.trim()=="") return this.$message.warning("留言不能为空")

        // 发送留言请求
        this.requestMethods&&this.requestMethods(this.formData);
      }
    }
  }
</script>
<style scoped lang="scss">
@import "@/assets/style/basic.scss";
  .leave-word{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    padding: 15px ;
    z-index: 1000;
    &-tips{
      margin-bottom: 15px;
    }
    &-input{
      margin-bottom: 15px;
    }
    &-btn{
      margin:0 auto;
      text-align: center;
      span{
        display: inline-block;
        width: 70px;
        height: 30px;
        line-height: 30px;
      }
      span+span{
        margin-left: 20px;
      }
      &_common{
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #999;
      }
      &_primary{
        @include background-main-color($theme_sky);
        border-radius: 4px;
        color: #fff;
      }
    }
  }
</style>